import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Divider, Stack, SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material';
import InstagramIcon from '../../assets/images/InstagramIcon.svg';
import Link from '../Link';

const Footer = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack
      py={4}
      px={4}
      sx={{ backgroundColor: '#446252' }}
      useFlexGap
      component="footer"
    >
      <Stack
        spacing={{ xs: 6, md: 16 }}
        direction={{ xs: 'column', lg: 'row' }}
        justifyContent="space-between"
        useFlexGap
      >
        <Stack
          direction="column"
          useFlexGap
          flex={1}
        >
          <Typography
            variant="body1"
            sx={{ color: 'white' }}
            fontSize={17}
            fontWeight={300}
          >
            Sprechstunden:
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'white' }}
            fontSize={17}
            fontWeight={300}
          >
            Montag - Freitag: 8 - 12 Uhr
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: 'white' }}
            fontSize={17}
            fontWeight={300}
          >
            Montag & Donnerstag: 15 - 18 Uhr
          </Typography>
        </Stack>
        <Stack
          direction="column"
          useFlexGap
          flex={1}
        >
          <Link
            href="https://maps.app.goo.gl/LJbkRvjjvtGBh3vb9"
            variant="body1"
            underline="always"
            fontSize={17}
            fontWeight={300}
            sx={{ color: 'white', textDecorationColor: 'white' }}
          >
            Siegfriedstraße 3 <br />
            67547 Worms-Ost
          </Link>
          <Link
            href="tel:+49624144555"
            variant="body1"
            underline="none"
            mt={2}
            fontSize={17}
            fontWeight={300}
            sx={{ color: 'white' }}
          >
            06241 44 555
          </Link>
          <Link
            href="mailto:kontakt@diehausaerzte-worms.de"
            variant="body1"
            underline="always"
            fontSize={17}
            fontWeight={300}
            sx={{ color: 'white', textDecorationColor: 'white' }}
          >
            kontakt@diehausaerzte-worms.de
          </Link>
        </Stack>
        <Stack
          spacing={0.5}
          useFlexGap
          flex={1}
        >
          <Stack
            direction="row"
            alignItems="center"
          >
            <SvgIcon
              component={InstagramIcon}
              inheritViewBox
              sx={{ color: 'white' }}
            />
            <Link
              href="http://instagram.com/diehausaerzte.worms/"
              variant="body1"
              underline="always"
              ml={1}
              sx={{ color: 'white', textDecorationColor: 'white' }}
              fontSize={17}
              fontWeight={300}
            >
              Instagram
            </Link>
          </Stack>
          <Stack
            display="none"
            direction="row"
            alignItems="center"
          >
            <FacebookIcon sx={{ fontSize: '22px', color: 'white' }} />
            <Link
              href="#"
              variant="body1"
              underline="always"
              ml={1}
              sx={{ color: 'white', textDecorationColor: 'white' }}
              fontSize={17}
              fontWeight={300}
            >
              Facebook
            </Link>
          </Stack>
          <Stack
            display="none"
            direction="row"
            alignItems="center"
          >
            <LinkedInIcon sx={{ fontSize: '22px', color: 'white' }} />
            <Link
              href="#"
              variant="body1"
              underline="always"
              ml={1}
              sx={{ color: 'white', textDecorationColor: 'white' }}
              fontSize={17}
              fontWeight={300}
            >
              LinkedIn
            </Link>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={2}
        mt={{ xs: 6, md: 16 }}
        useFlexGap
      >
        <Typography
          variant="body1"
          sx={{ color: 'white' }}
          fontSize={17}
          fontWeight={300}
        >
          Die Hausärzte Worms 2024
        </Typography>
        {isTablet && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: 'white' }}
          />
        )}
        <Link
          to="/imprint"
          variant="body1"
          underline="none"
          fontSize={17}
          fontWeight={300}
          sx={{ color: 'white', textDecorationColor: 'white' }}
        >
          Impressum
        </Link>
        {isTablet && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: 'white' }}
          />
        )}
        <Link
          to="/privacypolicy"
          variant="body1"
          underline="none"
          fontSize={17}
          fontWeight={300}
          sx={{ color: 'white', textDecorationColor: 'white' }}
        >
          Datenschutz
        </Link>
      </Stack>
    </Stack>
  );
};

export default Footer;
